@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  /* src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
            url(https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lujVj9_mf.woff2) format('woff2'); */
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
    url(./fonts/SourceSansPro-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLightItalic'), local('SourceSansPro-ExtraLightItalic'),
    url(./fonts/SourceSansPro-ExtraLightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url(./fonts/SourceSansPro-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro LightItalic'), local('SourceSansPro-LightItalic'),
    url(./fonts/SourceSansPro-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(./fonts/SourceSansPro-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'), url(./fonts/SourceSansPro-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), url(./fonts/SourceSansPro-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBoldItalic'), local('SourceSansPro-SemiBoldItalic'),
    url(./fonts/SourceSansPro-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(./fonts/SourceSansPro-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro BoldItalic'), local('SourceSansPro-BoldItalic'),
    url(./fonts/SourceSansPro-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'), url(./fonts/SourceSansPro-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro BlackItalic'), local('SourceSansPro-BlackItalic'),
    url(./fonts/SourceSansPro-BlackItalic.ttf) format('truetype');
}
