.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.bottomFooter {
  position: fixed;
  bottom: 0px;
  width: 100%;
}
