.footer {
  background-color: #305e9c;
  color: #bdd6f5;
  width: 100%;
}
.smallText {
  font-size: 15px;
}
.footerText {
  color: #bdd6f5;
  font-size: 15px;
}
.footerText:hover {
  color: #68b1ff;
}
