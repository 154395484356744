body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f9fe !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  width: 100vw !important;
  height: 100vh !important;
  scroll-behavior: smooth;
  overflow: hidden;
}

/*Activity Loader modal background*/
#activityModal .modal-content,
#alertModal .modal-content {
  background-color: transparent;
  border: none;
  justify-content: center;
  align-items: center;
}

/*Header bar dropdown icon removed and added custom*/
#header-nav-dropdown:after {
  display: none;
}

#tab-tab-booking.active,
#tab-tab-shipping.active {
  color: #3a86dc;
  border-top: 3px solid #3a86dc !important;
  border-bottom: 0px !important;
}
#tab-tab-booking,
#tab-tab-shipping {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
