.container {
  /* background-color: #282c34; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  height: calc(100vh - 134px);
  /* overflow: hidden; */
  overflow-y: auto;
  /* overflow-x: hidden; */
  padding: 0px;
}
.tabsContainer {
  /* width: 100%; */
  height: 100%;
}
.tabsRow {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  margin: 0px;
}
.tabsCol {
  /* overflow: auto; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.tabWindow {
  /* background-color: #282c34; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-left: 1px solid #dfe2e6;
  border-right: 1px solid #dfe2e6;
  border-bottom: 1px solid #dfe2e6;
  padding: 25px;
}
.tabWidth {
  width: 650px;
}
.txtTabNames {
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  color: #000;
  padding: 8px 0px;
  background-color: #fff;
  border: 1px solid #dee2e6 !important;
}
.txtUploadText {
  font-size: 18px;
}
.txtUploadTextConditions {
  font-size: 16px;
}
.txtSelectedFilesTitle {
  font-size: 16px;
  color: #000;
}
.labelHeaders {
  font-size: 18px;
  color: #333;
}
.dropdownArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #4076b8;
  border-style: dashed;
  background-color: #3a86dc;
  color: #ffffff;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.imgExcelUpload {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
}
.imgSelectFiles {
  height: 25px;
  width: 21px;
  margin: 5px 7px;
  border: none;
}
.btnSelectFiles {
  font-size: 15px;
  color: #000;
  border-radius: 3px;
  width: 180px;
  height: 40px;
  border: none !important;
  outline: none !important;
}
.txtSelectedFileName {
  font-size: 16px;
  color: #01499e;
}
.selectedFilesDiv {
  max-height: 135px;
  overflow-y: auto;
}
.rowDiv {
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: #e0e0e0 1px solid;
  border-radius: 3px;
  margin-top: 3px;
  background-color: #fafafa;
  overflow: hidden;
}
.removingFile {
  height: 0px;
  transition: height 0.25s ease;
}
.imgExcel {
  height: 20px;
  margin-left: 12px;
  margin-right: 10px;
  margin-top: -5px;
}
.imgClose {
  height: 25px;
  width: 25px;
  border-radius: 12.5px;
  padding: 3px;
}
.imgClose:hover {
  cursor: pointer;
  background-color: #e0e0e0;
}
.btnBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}
.captcha {
  align-self: center;
  font-size: 20px;
  margin: 20px 10px 10px 0px;
}

.btnDownload {
  cursor: pointer;
  font-size: 18px;
  border-radius: 3px;
  background-color: #aaaaaa;
  margin-bottom: 10px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  line-height: 16px;
  padding: 3px 0px 0px 0px;
  border: none !important;
  outline: none !important;
}
.btnDownload:hover {
  background-color: #999999;
}
.btnSubTitle {
  font-size: 13px;
}
.btnUpload {
  cursor: pointer;
  font-size: 18px;
  border-radius: 3px;
  background-color: #69a5f8;
  margin-bottom: 10px;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  line-height: 16px;
  padding: 3px 0px 0px 0px;
  border: none !important;
  outline: none !important;
}
.btnUpload:disabled:hover {
  cursor: default;
  background-color: #69a5f8;
}
.btnUpload:hover {
  background-color: #5484c6;
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
  .tabWidth {
    width: 400px;
  }
  .dropdownArea {
    padding: 10px;
  }
  .tabWindow {
    padding: 5px 0px;
  }
  .btnBar {
    flex-direction: column;
  }
}
@media only screen and (max-width: 576px) {
  .tabWidth {
    width: 85%;
  }
  .dropdownArea {
    padding: 10px;
  }
  .tabWindow {
    padding: 5px 0px;
  }
  .txtTabNames {
    font-size: 16px;
    line-height: 20px;
  }
  .txtUploadText {
    font-size: 14px;
    text-align: center;
  }
  .txtUploadTextConditions {
    font-size: 14px;
  }
  .txtSelectedFilesTitle {
    font-size: 14px;
    color: #000;
  }
  .labelHeaders {
    font-size: 18px;
    color: #333;
    width: 200px;
  }
  .btnBar {
    flex-direction: column;
  }
  .captcha {
    margin: 0px;
  }
  .btnUpload {
    margin: 2px 0px;
  }
}
