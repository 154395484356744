.headerBar {
  background-color: #ffffff;
  /* boxS-shadow: 0px 4px 10px #aaaaaa; */
  height: 80px;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  /* position: static !important; */
}
.logo {
  height: 35px;
}
.titleText {
  font-size: 20px;
  color: #33609a;
  padding: 0px;
  margin: 0px 20px;
  font-family: 'Source Sans Pro', sans-serif !important;
}
.AssumeTitleText {
  font-size: 16px;
  padding: 0px;
  margin: 0px 20px;
  color: grey;
  font-family: 'Source Sans Pro', sans-serif !important;
}
.titleText_Bold {
  font-size: 20px;
  font-weight: 700;
  color: #33609a;
  padding: 0px;
  margin: 0px 20px;
  font-family: 'Source Sans Pro', sans-serif !important;
}
.navbar-brand {
  margin: 0px;
}
.collasibleNavDropdown,
.collasibleNavDropdown > a {
  background-color: #f1f1f1 !important;
  color: #777777 !important;
  border-color: #f1f1f1 !important;
  border-radius: 3px;
}
.imgFlag {
  margin: 0px 10px;
  cursor: pointer;
}
.modal-90w {
  width: 30%;
}

.modal-dialog {
  max-width: 90% !important;
  margin: auto;
  margin-top: 80px;
}
.AssumeTitleText {
  font-size: 20px;
  padding: 0px;
  margin: 0px 20px;
  color: grey;
  font-family: 'Source Sans Pro', sans-serif !important;
}
.button {
  align-self: center;
  font-size: 20px;
  margin-bottom: 10px;
  background-color: #69a5f8;
  border-color: #69a5f8;
}
.button_yes {
  align-self: center;
  font-size: 20px;
  background-color: #69a5f8;
  border-color: #69a5f8;
}
.button_no {
  align-self: center;
  font-size: 20px;
  background-color: #999999;
  border-color: #999999;
}
.button_no:hover {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}
.infobar {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  background-color: #f1f1f1;
}
.txtInfoNote {
  font-size: 16px;
  background-color: lightskyblue;
  font-weight: 600;
  padding: 3px;
}
@media only screen and (max-width: 576px) {
  .headerBar {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .infobar {
    padding-left: 15px;
    padding-right: 15px;
  }
}
